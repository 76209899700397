import React from 'react'

import logo from './../../assets/rymdskepp.svg'

import './app.css'

const App = () => {
    return(
        <div>
            <h1>
                <img src={logo} alt="rymdskepp" />
            </h1>
        </div>
    )
}

export default App